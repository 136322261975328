.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}

.logo-img {
    height: 50px !important;
}

.header-bar {
    border-radius: 10px;
    background: #FFF;
    filter: drop-shadow(2px 4px 30px rgba(0, 0, 0, 0.20));
    display: flex;
    flex-direction: row;
}

.header-link {
    padding: 15px 25px;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    cursor: pointer;

}

.header-link:hover {
    text-decoration: none;
}

.header-mobile-icon {
    color: #FFF;
    cursor: pointer;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {

    .logo-img {
        height: 40px !important;
    }

    .header-bar {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 30px;
        top: 70px;
        width: 40%;
    }
}