.home-container {
    display: flex;
    flex-direction: row;
    min-height: 70vh;
}

.upload-main-container {
    flex: 1;
    /* background-color: red; */
}

.upload-container {
    height: 400px;
    width: 100%;
    /* max-width: 300px; */
    border-radius: 20px;
    background-color: #FFF;
    box-shadow: 3px 4px 40px 0px rgba(0, 0, 0, 0.30);
    transition: 0.5s;
}

.upload-container:hover {
    box-shadow: 10px 10px 30px #0e0e0e;
    background-color: #e9e9e9;
}

.slide-content {
    flex: 2.7;
    /* background-color: red; */
    padding: 70px;
    padding-top: 0px;
    /* max-width: 600px; */
    align-items: end;
}

.slide-heading {
    color: #FFF;
    font-family: Prata;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.slide-caption {
    color: #dfdfdf;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: right;
    max-width: 500px;
    /* 150% */
}

.silde-button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
    width: 150px;
    height: 40px;
    border-radius: 30px;
    background: #F7F7F7;
    color: #342A45;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    /* line-height: normal; */
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
}

.silde-button:hover {
    background: #d6d6d6;
}

.slide-image-container {
    flex: 0.45;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.slide-image {
    width: 90%;
    max-width: 600px;

}

.upload-inner-container {
    padding: 15px;
    /* background-color: red; */
}


.u-image-main-container {
    min-height: 80px;
    margin-top: 20px;
}

.u-images-container {
    position: relative;

}

.u-image-rotate1 {
    transform: rotate(20deg);
}

.u-image-rotate2 {
    transform: rotate(-5deg);
    margin-left: 5px;
}

.u-image-container {
    border: 3px solid #FFF;
    background-color: #5A1BDC;
    /* background-color: #f7f3ff; */
    filter: drop-shadow(1px 4px 2px rgba(0, 0, 0, 0.40));
    border-radius: 10px;
    padding: 5px;
}

.u-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 50px;
    height: 50px;
    margin-top: 0px;
}




.upload-email {
    margin-top: 40px !important;
    width: 90%;
    font-size: 12px;
}


.re-upload-button {
    color: #7334f1;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    cursor: pointer;
}

.image-counter {
    position: absolute;
    top: -7px;
    right: -5px;
    background-color: #E03D19;
    color: #FFF;
    font-family: Roboto;
    font-weight: 600;
    /* padding: 3px; */
    font-size: 12px;
    height: 18px;
    width: 18px;
    /* text-align: center; */
    border-radius: 100px;
}

.h-drop-zone{
    cursor: pointer;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .home-container {
        display: block;
    }

    .upload-container {
        /* width: 80%; */
        margin-top: 30px;
    }

    .slide-content {
        padding: 100px 0px;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 2560px) {
    .upload-container {
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .slide-content {
        flex: 4;
    }

}

@media screen and (max-width: 1024px) {
    .slide-content {
        flex: 1.5;
    }
}