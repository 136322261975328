body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  padding: 10px;
  position: relative;
}

.text-white {
  color: #FFF;
}


.row {
  display: flex;
  flex-direction: row;
}

.height100 {
  min-height: 100vh;
}

.column {
  display: flex;
  flex-direction: column;
}


.center {
  justify-content: center;
  align-items: center;
}

.center-vertical {
  align-items: center;
}

.center-horizontal {
  justify-content: center;
}

.upload-plus-icon {
  height: 80px;
  margin-top: 20px;

}

.upload-title {
  margin-top: 30px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.uplaod-caption {
  color: #1a1a1a;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upload-submit-btn {
  margin-top: 20px;
  border-radius: 15px;
  background: #5A1BDC;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px 15px;
  transition: 0.5s;
  cursor: pointer;
}

.upload-submit-btn:hover {
  background: #7334f1;
  filter: drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.40));
}

.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black w/ opacity */
}

.share-modal {
  /* Sit on top */
  left: 0;
  top: 0;
}


.tools-modal {
  /* Sit on top */
  left: 0;
  top: 0;
}

.modal-header {
  height: 20px;
  background-color: #5A1BDC;
  border-radius: 9px 9px 0px 0px;
  padding: 20px;
  color: #FFF;
  font-weight: 700;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-close {
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
}

.copy-link-modal {
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.copy-link-modal>span {
  margin-left: 3px;
  font-weight: 800;
}

.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 10px;
}

.share-modal-content {
  margin: 5% auto;
}

.tools-modal-content {
  margin: 3% auto;
}

.modal-body {
  padding: 20px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.primary-text {
  color: #7334f1 !important;
}

.primary-dark-text {
  color: #3e0f9c !important;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
  .share-modal-content {
    width: 90% !important;
    margin-top: 10% !important;
  }
}