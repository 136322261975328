.main-containter {
  /* background-image: linear-gradient(50deg, #4F23AC,  #211A30); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  padding: 50px;
  transition: 0.8s;
}

body{
  background-color: #1A004F;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {
  .main-containter {
    padding: 35px;
  }
}