.f-container {
    margin-top: 100px;
    padding-right: 6%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.f-container>a {
    color: #FFF;
    text-decoration: none;
    font-weight: 900;
}

.f-container>span {
    color: #FFF;
    font-size: 12px;
    margin: 0px 5px;
    font-weight: 800;
}