.art-container {
    background-color: #FFF;
    border-radius: 20px;
    padding: 50px;
    position: relative;
    margin-top: 90px;
}

.art-wizard-container {
    padding: 0px 50px;
    justify-content: space-between;
}

.art-wizard-item {
    flex: 1;
    z-index: 1;
}

.art-wizard-item-sub {
    cursor: pointer;
}

.art-wizard-item-sub:hover>.art-wizad-icon-container.inactive {
    background-color: #361874;
}

.art-wizad-icon-container {
    background-color: #4D425F;
    border-radius: 100px;
    padding: 15px;
    transition: 0.3s;
}

.art-wizad-icon-container.active {
    background-color: #7334f1;
}

.art-wizard-icon {
    height: 38px;
    width: 38px;

}

.art-wizard-title {
    margin-top: 10px;
}

.art-wizard-line-container {
    position: absolute;
    width: 90%;
    top: 80px;
    left: 70px;
}

.art-wizard-line {
    height: 5px;
    background-color: #C3C3C3;
    width: 65%;
}

.art-wizard-content {
    padding: 65px 100px
}

.art-wizard-heading {
    color: #342A45;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.art-config-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
}

.art-config-field-wrapper {
    flex: 1;
    margin-right: 30px;
    margin-left: 0px;
}

.art-config-field {
    width: 90%;
}

.art-config-field-wrapper.rotate {
    cursor: pointer;
    margin-right: 0px !important;

    /* justify-content: space-between; */
}

.art-rotate-icon-container {
    margin-left: 30px;
}

.art-rotate-icon {
    font-size: 20px;
    color: #7334f1;
}

.art-rotate-icon.check {
    color: #009467;
}

.art-rotate-gang-label {
    font-size: 12px;
}

.art-preview-container {
    flex-wrap: wrap;
}


.art-preview-item {
    border-radius: 20px;
    border: 1px solid #E4E3E3;
    width: 30%;
    margin-right: 3%;
    min-height: 400px;
    margin-bottom: 30px;
    transition: 0.5s;


    box-shadow: 1px 0px 10px #575757;
}

.art-preview-item.upload:hover {
    background-color: #FFF;

    /* box-shadow: 1px 0px 10px #575757; */
}

.art-preview-item.upload {
    cursor: pointer;
    background-color: #f5f5f5;
}

.art-preview-dpi {
    border-radius: 19px 19px 0px 0px;
    background: rgba(16, 170, 204, 0.80);
    padding: 12px;

    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.art-preview-transparent {
    /* width: 285.117px; */
    /* height: 250px; */
    /* width: auto; */
    /* flex-shrink: 0; */
}

.art-preview-item-overlay {
    background-color: #ffffff;
    border-bottom: 1px solid #E4E3E3;

    min-height: 250px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
}

.art-preview-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.art-remove-icon {
    z-index: 1;
    background-color: #BF0000;
    color: #FFF;
    padding: 4px;
    height: 18px;
    width: 18px;
    border-radius: 100px;
    font-size: 16px;
    position: absolute;
    right: 0px;
    right: 5px;
    top: 5px;
    text-align: center;
    cursor: pointer;
}

.art-size-container {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-radius: 5px;
    /* border-radius: 0px 10px 0px 0px; */
    background: #FFF;
    padding: 7px;
    /* width: 40%; */

    color: #7334f1;
    text-align: center;
    font-family: Alike;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: 0.5s;
    z-index: 1;
}

.art-size-container:hover {
    background-color: #7334f1;
    color: #FFF;
    box-shadow: 1px 2px 4px #888888;
}

.art-bottom-content {
    padding: 20px;
}

.art-bottom-config {}

.art-bottom-config-field {
    margin: 10px !important;
}

.art-sizeup-container {}

.size-up-heading {
    color: #767676;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.art-sizeup-row-container {
    margin-top: 20px;
}

.art-sizeup-item {
    border-radius: 10px;
    background: #D9D9D9;
    color: #4A4949;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px;
    margin: 0px 5px;
    transition: 0.5ms;
    cursor: pointer;
    width: 15px;
    text-align: center;
}

.art-sizeup-item.active {
    transition: 0.5s;
    background-color: #6C35DE;
    color: #FFF;
}

.art-sizeup-item.inactive:hover {
    background: #ededed;
}

.art-submit-button {
    width: 25%;
    margin-right: 3%;
}

.art-wizard-busy {
    height: 300px;
}

.art-download-image-main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;

}

.art-download-image-container {
    height: 350px;
    min-width: 30%;
    width: 30%;
    margin: 10px;

    border: 1px solid #E4E3E3;
    /* box-shadow: 1px 0px 10px #575757; */
    /* background-color: #cecece; */
    border-radius: 5px;
}

.art-download-image {
    /* height: 400px; */
    width: 90%;
    height: 90%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    
}

.art-download-img {
    object-fit: contain;
    background-color: #888888;
    background-image: linear-gradient(45deg, #666666 25%, transparent 25%), linear-gradient(-45deg, #666666 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #666666 75%), linear-gradient(-45deg, transparent 75%, #666666 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    /* box-shadow: 1px 0px 10px #575757; */
    max-width: 300px;
}

.art-download-title {
    color: #131313;
    text-align: center;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.art-download-caption {
    margin-top: 15px;
    color: #3e3e3e;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.art-download-button-container {
    margin-top: 10px;
}

.art-download-button {
    text-decoration: none;
    padding: 12px;
    width: 120px;
    margin-left: 10px;
}

.art-download-button>img, .art-share-button>img {
    height: 20px;
    margin-right: 6px;
}

.art-preview-transparent {
    background-color: #888888;
    background-image:
        linear-gradient(45deg, #666666 25%, transparent 25%),
        linear-gradient(-45deg, #666666 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #666666 75%),
        linear-gradient(-45deg, transparent 75%, #666666 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.art-share-button {
    border-radius: 15px;
    background: #979797;
    padding: 12px;
    width: 120px;
    /* margin-left: 10px; */
}

.art-share-button:hover {
    background-color: #009467;
}

.art-link {
    cursor: pointer;
    margin-top: 15px;
    color: #6C35DE;
    font-weight: 700;
    text-decoration: underline;
}

.email-container {}

.share-email-field {
    flex: 1;
}

.share-email-button {
    flex: 0.15;
    margin-top: 0px;
    margin-left: -2px;
    border-radius: 0px 5px 5px 0px;
    text-align: center;
    padding-top: 17px;
    font-weight: 700;
}

.clip-board-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.clipboard-sub-heading {
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 15px;
    color: #666666;
    text-align: center;
}

.art-wizard-url {
    color: #7334f1;
    font-size: 13px;
    margin-top: 0px;
}

.art-clipboard-button {
    font-size: 12px;
    font-weight: 700;
    margin-top: -5px;
    height: 8px;
    width: 100px;
    padding-top: 8px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.art-clipboard-button>span {
    margin-left: 5px;
}

.sharing-field {
    width: 300px;
    margin: 5px 0px !important;
}

.sharing-field-label {
    font-size: 13px;
    margin: 0px;
    margin-top: 10px;
    font-weight: 500;
}

.stext-area {
    padding: 10px;
    width: 270px;
}

.art-share-attach-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.art-share-attach {
    font-size: 12px;
    text-align: left;
}

.ced-container {
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 15px;
    position: absolute;
    right: -20px;
    top: -20px;
    background-color: #009467;
    border-radius: 0px 0px 0px 10px;
    padding: 10px;
    color: #FFF;
}

.ced-container>i {}

.ced-container>span {
    margin-left: 5px;
}

.clipboard-form {
    margin-top: -5px !important;
}

.qtm-container {
    height: 75vh;
    display: flex;
    flex-direction: row;
    padding: 0px;
}

.qtm-sidebar {
    flex: 0.3;
    background-color: #2F3539;
    overflow: scroll;
    border-radius: 0px 0px 0px 8px;
}

.qtm-nav {
    padding: 20px;
    color: #dadada;
    display: block;
    padding: 18px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: 0.5s;
}


.qtm-nav.active, .qtm-nav:hover {
    background-color: #8c53ff;
    color: #FFF;
}

.qtm-nav>i {}

.qtm-nav>span {
    margin-left: 5px;
    font-weight: 800;
}

.qtm-nav-heading {
    margin-top: 10px;
    padding: 17px 20px;
    color: #FFF;
    background-color: #565656;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.qtm-nav-heading>span {
    font-weight: 800;
    font-size: 14px;
}

.qtm-nav-heading>i {
    font-size: 12px;
}

.qtm-content {
    flex: 1;
    height: 100%;
    /* background-color: green; */
}

.qtm-preview-image {
    height: 80%;
    background-color: #3f3e3e;

}

.qtm-tool-container {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qtm-fields {}

.qtm-ud {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.qtm-ud>i {
    font-size: 50px;
    margin-bottom: 20px;
    color: #6C35DE;
}

.qtm-ud>span {
    font-weight: 700;
}

.qtm-buttons {
    flex: 0.5;
    display: flex;
    justify-content: end;

    /* background-color: red;s */
}

.qtm-button-gray {
    background-color: #888888;
}

.qtm-button>i {
    font-size: 14px;
}

.qtm-button>span {
    margin-left: 7px;
    font-weight: 800;
}

.qtm-sizeup-container {
    padding: 10px 20px;
}

.qtm-sizeup-item {}

.qtm-sizeup-heading {
    color: #FFF;
    font-size: 12px;
    margin: 7px 5px;
}

.qtm-sizeup-buttons {
    display: flex;
    flex-direction: row;
}

.qtm-sizeup-button {
    border-radius: 7px;
    background-color: #8d8d8d;
    color: #e6e6e6;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
    margin: 0px 5px;
    transition: 0.5s;
    cursor: pointer;
    text-align: center;

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.qtm-sizeup-button:hover {
    background-color: #6C35DE;
}

.qtm-sizeup-button>i {
    /* color: #6C35DE; */
}

.qtm-sizeup-button>span {
    margin-left: 3px;
    /* margin-top: 8px; */
}

.qtm-label-field {
    width: 35%;
    /* background-color: red; */
}

.qtm-tool-label {
    justify-content: start;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .art-container {
        margin-top: 30px;
        padding: 10px;
        margin-bottom: 300px;
    }

    .art-wizard-container {
        padding: 0px;
        margin-top: 20px;
    }

    .art-wizad-icon-container {
        /* padding: 10px; */
        height: 25px;
        width: 25px;
        text-align: center;
    }

    .art-wizard-icon {
        height: 20px;
        width: 20px;
    }

    .art-wizard-line-container {
        top: 60px;
        left: 0px;
    }

    .art-wizard-title {
        font-size: 10px;
    }

    .art-wizard-content {
        padding: 30px 20px;
    }

    .art-config-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .art-config-field-wrapper {
        margin-right: 0px;
        margin: 5px;
        min-width: 40%;
    }

    .art-preview-item {
        width: 100%;
    }

    .art-preview-item.upload {
        min-height: 300px !important;
    }

    .art-submit-button {
        width: 80%;
        margin-left: 10px;
    }

    .art-download-title {
        font-size: 18px;
        margin-top: 20px;
    }

    .art-download-caption {
        font-size: 14px;
    }

    .art-download-image-container {
        margin-top: 20px;
        width: 100%;
        height: 300px;
    }

    .art-download-button, .art-share-button {
        width: 110px;
        padding: 12px;
        font-size: 14px;
    }

    .art-download-button>img, .art-share-button>img {
        height: 15px;
        margin-right: 5px;
    }

    .art-clipboard-button {
        height: 15px !important;
        padding: 12px !important;
    }


    .qtm-container {
        position: relative;
        /* height: 100vh; */
    }

    .qtm-sidebar {
        flex: 1;
        position: absolute;
        z-index: 99;
        height: 75vh;
    }

    .qtm-nav {
        /* width: 80%; */
    }

    .qtm-preview-image {
        height: 72%;
    }

    .qtm-tool-container {
        display: flex;
        flex-direction: column;
    }

    .qtm-fields {
        display: flex;
        flex-direction: row;
    }

    .qtm-field {}

    .qtm-button {
        width: 150px !important;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}